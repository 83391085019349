import request from '@/utils/request'

export function getMainList(params) {
  return request({
    url: '/api/get-main-list',
    method: 'GET',
    params
  })
}
export function getUnfoundRecord(params) {
  return request({
    url: '/api/get-unfound-record',
    method: 'GET',
    params
  })
}
export function getAbnormalRecord(params) {
  return request({
    url: '/api/get-abnormal-record',
    method: 'GET',
    params
  })
}

export function setOvertime(id) {
  return request({
    url: `/api/set-record-time/${id}`,
    method: 'PUT',
  })
}
export function setOvertimeMulti(data) {
  return request({
    url: '/api/set-record-time-multi-choices',
    method: 'PUT',
    data
  })
}
//已在案的類似電影名稱 recorded film title
export function getTheSameFilmName(circuit_id, pos_id, search) {
  return request({
    url: `/api/film-circuit/${circuit_id}/${pos_id}/similar?search=${search}`,
    method: 'GET',
  })
}
//未在案的類似電影名稱 Unrecorded film title
export function getUnrecordedFilmName(data) {
  return request({
    url: '/api/search-pos-id',
    method: 'POST',
    data
  })
}

export function getFilmByPosid(id) {
  return request({
    url: `/api/${id}/pos-ids`,
    method: 'GET',
  })
}

export function setLinkToFilm(data) {
  return request({
    url: `/api/link-film`,
    method: 'PUT',
    data
  })
}

export function deletFilmRecord(film_id, data) {
  return request({
    url: `/api/abnormal-record/set-status/${film_id}`,
    method: 'PUT',
    data
  })
}
export function setStatusMulti(data) {
  return request({
    url: '/api/abnormal-record/set-status-multi-choices',
    method: 'PUT',
    data
  })
}
export function setAllStatus() {
  return request({
    url: '/api/abnormal-record/set-all-status',
    method: 'PUT'
  })
}

export function addToOthorPosId(oldPosId, newPosId) {
  return request({
    url: `/api/save-pos-id/${oldPosId}/${newPosId}`,
    method: 'PUT'
  })
}

export function savePosId(data) {
  return request({
    url: `/api/change-pos-id`,
    method: 'PUT',
    data
  })
}

export function uploadXML(data) {
  return request({
    url: '/api/upload-xml',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryP9vs3KBjYyWmIaka',
    },
  })
}
export function getXmlFinal(data) {
  return request({
    url: '/api/get-xml-final',
    method: 'POST',
    data,
  })
}
export function getLatestXml(id, date) {
  return request({
    url: `/api/${id}/latest-xml?date=${date}`,
    method: 'GET'
  })
}

export function checkPosid(data) {
  return request({
    url: '/api/check-pos-id',
    method: 'POST',
    data
  })
}
export function deletePosId(id) {
  return request({
    url: `/api/delete-pos-id/${id}`,
    method: 'DELETE',
  })
}